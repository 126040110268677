import { type Observable, Subject, type Subscription } from 'rxjs';

type Index = string | number | symbol;

export class SubjectFactoryByProperty<P extends Index, V extends Index, O extends { [K in P]: V }> {
  private subjects = new Map<V, Subject<O>>();
  private subscription: Subscription;
  private error = (err: unknown) => {
    this.subjects.forEach(subject => subject.error(err));
  };
  private complete = () => {
    this.subjects.forEach(subject => subject.complete());
    this.subjects.clear();
  };
  private next = (message: O) => {
    const key = message[this.property];
    if (this.subjects.has(key)) {
      this.subjects.get(key)!.next(message);
    }
  };
  constructor(private property: P, observable: Observable<O>) {
    const { next, error, complete } = this;
    this.subscription = observable.subscribe({ next, error, complete });
  }
  public create<I extends V>(value: I): Observable<Extract<O, { [K in P]: I }>> {
    if (this.subjects.has(value) === false) {
      const subject = new Subject<Extract<O, { [K in P]: I }>>();
      this.subjects.set(value, subject as any as Subject<O>);
      return subject.asObservable();
    } else {
      return (
        this.subjects.get(value) as any as Subject<Extract<O, { [K in P]: I }>>
      ).asObservable();
    }
  }
  public delete<I extends V>(value: I) {
    if (this.subjects.has(value)) {
      this.subjects.get(value)!.complete();
      this.subjects.delete(value);
    }
  }
  public close() {
    this.complete();
    this.subscription.unsubscribe();
  }
}
