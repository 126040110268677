import { identity } from '@sgme/fp';

export function roundToPrecision(value: number, precision: number | undefined): number {
  if (precision === undefined) {
    return value;
  }
  return Math.round(value * tenPower(precision)) / tenPower(precision);
}

/**
 * Higher order function to add floating point numbers with the given precision
 * @param precision the number of fractional figures which need to be right
 * @see https://modernweb.com/what-every-javascript-developer-should-know-about-floating-points/
 */
export const precisionAdderWithPrecision =
  (precision: number) =>
  (...numbers: readonly number[]) =>
    +(
      numbers.reduce((sum, num) => sum + num * tenPower(precision), 0) / tenPower(precision)
    ).toFixed(precision);

/**
 * @param from starting integer
 * @param to ending integer
 * @returns an array containing the integers between `from` and `to`, including them
 * @example range(1, 3) => [1, 2, 3]
 */
export function range(from: number, to: number): readonly number[] {
  return rangeMap(from, to, identity);
}

/**
 * @param from starting integer
 * @param to ending integer
 * @param mapper function that accepts the integer between `from` and `to` and return a `T`
 * @returns an array of `T`
 */
export function rangeMap<T>(from: number, to: number, mapper: (value: number) => T): readonly T[] {
  const current: (i: number) => number = from <= to ? i => i + from : i => from - i;
  return Array.from({ length: Math.abs(to - from) + 1 }, (_, i) => mapper(current(i)));
}

const powersOfTen = rangeMap(0, 10, x => 10 ** x);

/**
 * Elevates the exponent to the power of ten (=== Math.pow(10, exponent))
 * @param exponent
 */
export const tenPower = (exponent: number) => powersOfTen[exponent] ?? 10 ** exponent;

export function transformStringNum(transform: (n: number) => number) {
  return (value: string | null): string | null =>
    value === null ? null : transform(Number.parseFloat(value)).toString();
}
